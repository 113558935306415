import React, {useContext, useState} from 'react';
import {MainContext} from '../../context';
import SlideToggle from "react-slide-toggle";
import { ReactComponent as ReadIcon } from '../../assets/icons/read.svg';
import { ReactComponent as ArrowTopIcon } from '../../assets/icons/arrow-top.svg';
import { ReactComponent as ImgBlockIcon } from '../../assets/icons/img-block.svg';
import { ReactComponent as VideoIcon } from '../../assets/icons/video.svg';
import SpeechButton from '../SpeechButton';
import PlaceCode from '../PlaceCode';
import ImageGallery from '../ImageGallery';
import YouTube from 'react-youtube';
import {youtube_parser} from '../../functions';

const SubRoomListItem = (props) => {
    const {states} = useContext(MainContext);
    const [scrollTop, setScrollTop] = useState(window.pageYOffset);
    const room = props.room;
    const scrollTarget = props.scrollTarget;
    return (
        <div className="subroom-list-item-main" style={{borderColor: '#'+states.currentMuseum.light_color}} ref={document.location.hash === '#'+room.place_code ? scrollTarget : null}>
            <SlideToggle 
                collapsed={document.location.hash !== '#'+room.place_code} 
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                <>
                    <div className="subroom-list-item" onClick={() => {if(toggleState === 'COLLAPSED') setScrollTop(window.pageYOffset); toggle();}}>
                        <div className="subroom-numbers" style={{color: '#'+states.currentMuseum.light_color}}>
                            {room.show_no_code !== true && <PlaceCode text={room.place_code} />}
                        </div>
                        <span className="subroom-list-item-header">{room.title || room.name}</span>
                    </div>
                    {(room.text || room.images || room.video_link) && 
                        <div className="room-list-item-footer" id={'room-'+room.place_code}>
                            <div style={{display:'flex', justifyContent: 'space-between'}}>
                                <div className="item-icon-read" style={{flex:1}} onClick={() => {if(toggleState === 'COLLAPSED') setScrollTop(window.pageYOffset); toggle();}}>
                                    {toggleState === 'EXPANDED' ? <ArrowTopIcon fill="#0D3845" fillOpacity="0.5" /> : <ReadIcon fill="#0D3845" fillOpacity="0.5" />}
                                </div>
                                <div className="item-icon-listen" style={{backgroundColor: '#'+states.currentMuseum.light_color}}>
                                    <SpeechButton title={room.title} src={room.audio_url} />
                                </div>
                            </div>
                            <div className="room-list-item-text" ref={setCollapsibleElement}>
                                <div dangerouslySetInnerHTML={{__html: room.text}}></div>
                                {room.images &&<div className="settings-header header-pict-img">
                                    <ImgBlockIcon fill="#0D3845" fillOpacity="0.5" stroke="" />
                                    <div className="settings-header-text">{states.translators.images}</div>
                                </div>}
                                {room.images && <ImageGallery images={room.images} />}
                                {room.video_link && <div className="settings-header header-pict-img">
                                    <VideoIcon fill="#0D3845" fillOpacity="0.5" stroke="" />
                                    <div className="settings-header-text">{states.translators.video}</div>
                                </div>}
                                {room.video_link && 
                                    <YouTube videoId={youtube_parser(room.video_link)} opts={
                                            {
                                                width: '100%',
                                                playerVars: {
                                                    autoplay: 0,
                                                }
                                            }
                                        }
                                    />
                                }
                                <div className="collapse-btn" onClick={() => {if(scrollTop > 0) window.scrollTo(0, scrollTop); toggle();}}><ArrowTopIcon fill="#0D3845" fillOpacity="1" />{states.translators.hide}</div>
                            </div>
                        </div>
                    }
                </>
            )} />
        </div>
    );
}

export default SubRoomListItem;